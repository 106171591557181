import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { Form, Button } from 'react-bootstrap';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import FormContainer from '../components/FormContainer';
import '../styles/FormContainer.css';
import CryptoJS from 'crypto-js';
import { backOfficeLoginRequest } from '../actions/backOfficeAuthAction';

const BackOfficeLogin = ({ history }) => {

	// Initialize react state
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const form = useRef();

	// Read Redux state
	const dispatch = useDispatch();
	const backofficeAuthDetails = useSelector((state) => state.backofficeAuthState);
	const { loginSucceeded, error } = backofficeAuthDetails;

	useEffect(() => {

		if(loginSucceeded) {
			history.push('/backoffice');
		}

	}, [ loginSucceeded ] );

	const onSubmitHandler = (e) => {

		e.preventDefault();

		if (username && password) {
			const userId = username;
			const id = '' + CryptoJS.SHA256(userId);
			dispatch(backOfficeLoginRequest(username, password, id));
		}

	};

	{/*}
	return (
		<div className="bo-login">
			<FormContainer>
				<h2 className="heading">Login</h2>
				<Form onSubmit={(e) => onSubmitHandler(e)}>
					<Form.Group controlId="formBasicUsername">
						<Form.Label>Username</Form.Label>
						<Form.Control
							type="text"
							value={username}
							placeholder="Username"
							onChange={(e) => setUsername(e.target.value)}
						/>
					</Form.Group>

					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							value={password}
							placeholder="Password"
							onChange={(e) => setPassword(e.target.value)}
						/>
	 				</Form.Group>
					<Button variant="primary" type="submit">
						Log In
					</Button>

				</Form>
				{error && (
					<div className="signIn">
						<div role="alert">{error}</div>
					</div>
				)}
			</FormContainer>
		</div>
	);
	*/}


	return (
		<div className="col-md-12">
		  <div className="card card-container gc-card-container">
			<h2 className="gc-page-title">Login Page</h2>
			<img
			  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
			  alt="profile-img"
			  className="profile-img-card gc-avatar-img"
			/>
	
			<Form onSubmit={(e) => onSubmitHandler(e)} ref={form}>
				<div>
				  <div className="form-group">
					<label htmlFor="username">Username</label>
					<Input
					  type="text"
					  className="form-control"
					  name="username"
					  value={username}
					  onChange={(e) => setUsername(e.target.value)}
					/>
				  </div>
	
				  <div className="form-group">
					<label htmlFor="password">Password</label>
					<Input
					  type="password"
					  className="form-control"
					  name="password"
					  value={password}
					  onChange={(e) => setPassword(e.target.value)}
					/>
				  </div>
	
				  <div className="form-group">
					<button className="btn btn-primary btn-block">Log In</button>
				  </div>
				</div>
			</Form>
			{error && (
				<div className="signIn">
					<div role="alert">{error}</div>
				</div>
			)}
		  </div>
		</div>
	  );
};


export default BackOfficeLogin;