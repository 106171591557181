import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { select, Form, Button } from 'react-bootstrap';
import { sendFileData,activateReport, getPropertyList } from '../actions/backOfficeAction';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ToastContainer, toast } from 'react-toastify';
import { logout } from '../actions/backOfficeAuthAction';


const BackOfficeScreen = () => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [propertySelected, setPropertySelected] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const propertyList = useSelector((state) => state.propertyListState.propertyList);

	const dispatch = useDispatch();
	const onFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setErrorMessage("");
	};

	const submitFileHandler = (e) => {
		e.preventDefault();

		// Only submit the file if a property has been selected
		// and if a file has actually been chosen
		if(propertySelected) {
			if(selectedFile != null) {
		dispatch(sendFileData(selectedFile,e));
			}
			else {
				setErrorMessage("You must select a file to proceed");
			}
		}
		else {
			setErrorMessage("You must select a property name for the file before submitting");
		}
	};

	const propertySelectHandler = (e) => {
		e.preventDefault();
		setPropertySelected(true);
		setErrorMessage("");
	};

	// const downloadReportHandler = (e) => {
	// 	console.log('event value ', e.target.value);
	// 	e.preventDefault();

	// 	dispatch(downloadReport());
	// };

	const activateHandler = (e) => {
		e.preventDefault();

		dispatch(activateReport());
	};

	const logOutHandler = (e) => {

		dispatch(logout());

	}

	// Load the property list and update when it changes
	useEffect(() => {
		dispatch(getPropertyList());
	}, [dispatch]);

	return (
		<div className="col-md-12">
      		<div className="card card-container gc-backoffice-screen-container">
				<ToastContainer />{/*<Tabs defaultActiveKey="upload" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="upload" title="Upload" >*/}
				<form name="myForm" encType="multipart/form-data" action="" onSubmit={submitFileHandler}>
						<div className="backoffice-logout-link">
							<Button variant="link" onClick={logOutHandler}>
								Log Out
							</Button>
						</div>
						<h2 className="gc-page-title">Back Office Dashboard</h2>
					{/*
					<div className="form-group">
						<select name="Activate The list when select true" className="custom-select">
							<option value="false" selected>Select true if need to Activate the Giftcards list</option>
							<option value="true">true</option>
							<option value="false">false</option>
						</select>
					</div>
					<div className="form-group">
						<p align="left">Is this a bulk card file? Bulk card files are pre-purchased gift cards. If you are not sure, leave as false.</p>
						<select defaultValue="false" name="Select true if this is Bulk card file" className="custom-select">
							<option value="true">true</option>
							<option value="false">false</option>
						</select>
					</div>
					<div className="form-group">
						<p align="left">Validate the data only? If true, data will only be checked for validity and will not be saved.</p>
						<select defaultValue="false" name="Validate the Data before Upload" className="custom-select">
							<option value="true">true</option>
							<option value="false">false</option>
						</select>
					</div>
						*/}
					<div className="form-group">
							<p align="left" className="backoffice-paragraph"><strong>1. Select property name for this file</strong></p> 
						{
							// If the propertyList has been received from the server, display it as a pulldown list.
							// If not, just display a loading message.
							propertyList?.length > 0 ? (
									<select key={propertyList} defaultValue="-1" name="Property name for the file" className="custom-select" onChange={propertySelectHandler}>
										<option value="-1" disabled>Select the property name for this file</option>
									{propertyList.map((propertyEntry) => (
										<option key={propertyEntry.propertyId} value={propertyEntry.propertyId}>{propertyEntry.propertyName}</option>
									))}
								</select>
								) : (<div className="alert alert-danger" role="alert">
										<p>Property list not loaded from server. Please refresh and try again.</p>
									</div>)
						}
					</div>
					<div className="form-group">
							<p align="left" className="backoffice-paragraph"><strong>2. Select file to upload</strong></p> 
						<input
							type="file"
							name="file"
							className="form-control-file"
							id="ExcelFormControlFile"
							onChange={(e) => onFileChange(e)}
						></input>
					</div>
					<div className="backoffice-error-messages">
						{errorMessage.length > 0 ? <p>{errorMessage}</p> : ""}
					</div>
						<div className="backoffice-button-group">
						<Button variant="primary" type="submit" className="upload-button">
							Upload
						</Button>
						<Button variant="secondary" type="submit" onClick={activateHandler}> 
							Activate
						</Button>
					</div>
				</form>
					{/*</Tab>*/}
			{/* <Tab eventKey="download" title="Download">
				<Button variant="primary" onClick={downloadReportHandler}>
					Generate Report
				</Button>
					</Tab>
					</Tabs>*/}
				</div>
			</div>
	);
};

export default BackOfficeScreen;
