import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Order from '../components/Order';
import OrderTable from '../components/OrderTable';
import SystemError from '../components/SystemError';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { logOut } from '../actions/userAction';
import { updateOrderState, emptyOrders } from '../actions/orderAction';
import IdleTimer from '../components/IdleTimer';
import OverLay from '../components/OverLay';
import { useParams } from 'react-router-dom';

const OrderScreen = ({ history }) => {
	const { id } = useParams();
	const dispatch = useDispatch();

	const [redirectToHome, setRedirectToHome] = useState(false);
	const [show, setShow] = useState(false);

	const ordersInfo = useSelector((state) => state.ordersInfo);
	const loginDetails = useSelector((state) => state.loginDetails);
	const { userInfo } = loginDetails;
	const { loading, error, orders } = ordersInfo;

	const orderStatus = useSelector((state) => state.orderStatus);
	const { status } = orderStatus;
	useEffect(() => {
		if (status === 226) {
			history.push('./claimrewards');
		}
		let ordersId = JSON.parse(localStorage.getItem('orders-' + id));

		console.log('id in the usersId in the   compound did mount ===');
		if (ordersId && !redirectToHome) {
			dispatch(updateOrderState(ordersId));
		}
	}, [status,dispatch, history, id,redirectToHome]);
	
	useEffect(() => {
		const orders = JSON.parse(localStorage.getItem('orders-' + id));
		const usersId = JSON.parse(localStorage.getItem('reward-' + id));

		if (!usersId) {
			history.push('/');
		}

		/* setTimeOut pop's up Overlay component after 15 minutes */
		if (!show) {
			try {
				setTimeout(() => {
					setShow(true);
				}, 900000);
			} catch (error) {
				console.log('error', error);
			}
		}

		/* IdelTimer takes timeout, OnTimeout, onExpired as input and extends the users time if he or she on mutiple tabs
		and redirects to home page if user is not active for 30 minutes */
		if (!redirectToHome) {
			if (id) {
				const timer = new IdleTimer({
					timeout: 1800,
					userId: id,
					onTimeout: () => {
						if (!usersId) {
							history.push('./');
						}
						localStorage.removeItem('orders-' + id);
						localStorage.removeItem('reward-' + id);
						localStorage.removeItem('ex-' + id);
						setRedirectToHome(true);
						dispatch(logOut());
					},
					onExpired: () => {},
				});

				return () => {
					timer.cleanUp();
				};
			}
		}

		if (!orders) {
			history.replace('./');
		}
	}, [redirectToHome, show, id, dispatch, history, userInfo, status]);

	/* handleClose closes the OverLay if user clicks on yes button */

	const handleClose = () => {
		setShow(false);
	};

	/* handleLogout redirects to the  home page and  logOut the user
	  if users clicks on no button */

	const handleLogout = () => {
		const usersId = JSON.parse(localStorage.getItem('reward-' + id));
		if (usersId.id === id) {
			localStorage.removeItem('orders-' + id);
			localStorage.removeItem('reward-' + id);
			localStorage.removeItem('ex-' + id);
			history.push(`/`);
		}
		dispatch(logOut());

		setShow(false);
	};

	/* redirectToRewards redirect the user to  home page when user clicks
	on Return to Home Screen Button */

	const redirectToRewards = (e) => {
		e.preventDefault();

		//localStorage.removeItem('reward-' + id);
		localStorage.removeItem('order-' + id);

		localStorage.removeItem('ex-' + id);
		dispatch(emptyOrders());
		history.push(`/claimrewards/${id}`);
	};
	return (
		<div class="order-screen">
			<Header id={id} />
			{loading && (
				<div>
					<br />
					<Loader />
					<br />
					<p>We are processing your claim.</p>
					<strong>Processing may take a minute or two. Please be patient.</strong>
					<br />
				</div>
			)}
			{error && <SystemError children={error} />}
			{show && (
				<OverLay
					show={show}
					handleClose={handleClose}
					handleLogout={handleLogout}
				/>
			)}

			{orders?.length === 1 && (
				<div>
					<br />
					<h5>SUCCESS!</h5>
					{orders?.map((order) => (
						<Order order={order} loading={loading} />
					))}
				</div>
			)}
			{orders?.length > 1 && <OrderTable orders={orders} />}
			{(
				<div>
					<Button onClick={redirectToRewards} className="btn-return-home">
						Return to Home Screen
					</Button>
				</div>
			)}
			<Footer />
		</div>
	);
};
export default OrderScreen;
