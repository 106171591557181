import React from 'react';
import { Nav, Button } from 'react-bootstrap';

const PastRedeemTable = ({ userInfo }) => {
	const { giftcardListHistory } = userInfo;

	return (
		<div className="pastRedeem">
			<h3>Gift Cards for Ticket #{userInfo?.ticketNum}:</h3>
			<table class="table">
				<thead class="tablehead">
					<tr>
						<th scope="col">Swipe Date</th>
						<th scope="col">Date Claimed</th>
						<th scope="col">Order ID</th>
						<th scope="col">Brand</th>
						<th scope="col">Amount</th>
						<th scope="col">Gift Card Link</th>
					</tr>
				</thead>
				<tbody>
					{giftcardListHistory?.map((giftCard) => (
						<tr key={giftCard.awardId}>
							<td>{giftCard.swipeDate}</td>
							<td>{giftCard.redeemDate}</td>
							<td>{giftCard.alderOrderId}</td>
							<td>{giftCard.promotionName}</td>
							<td> ${giftCard.price}</td>
							<td>
								{giftCard.url.split(',').map((url) => (
									<Nav.Item>
										<Nav.Link href={url} target="_blank">
											{url}
										</Nav.Link>
									</Nav.Item>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default PastRedeemTable;
