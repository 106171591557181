import {
	GET_PROGRAMS_REQUEST,
	GET_PROGRAMS_SUCCESS,
	GET_PROGRAMS_FAIL,
	UPDATE_ORDER_STATE,
	GET_RESPONSE_STATUS,
	EMPTY_ORDERS,
} from '../constants/getOrderActions';

export const userOrdersReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROGRAMS_REQUEST:
			return { loading: true };
		case GET_PROGRAMS_SUCCESS:
			return { loading: false, orders: action.payload };
		case UPDATE_ORDER_STATE:
			return { netWorkFail: false, loading: false, orders: action.payload };
		case EMPTY_ORDERS:
			return { netWorkFail: false, loading: false, orders: action.payload };
		case GET_PROGRAMS_FAIL:
			return {
				loading: false,
				error:
					'The system is unable to process your gift card offer at this time. Please try again later. If you continue to receive this error please contact cs@sigmarsolutions.com.',
			};
		default:
			return { ...state };
	}
};
export const updateOrderData = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_ORDER_STATE:
			return { orders: action.payload };

		default:
			return { ...state };
	}
};
export const getOrderStatus = (state = {}, action) => {
	switch (action.type) {
		case GET_RESPONSE_STATUS:
			return { status: action.payload };
		default:
			return { ...state };
	}
};