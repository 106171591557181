import { updatedValues } from '../actions/userAction';
import {
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_REGISTERED_VALUES,
	USER_LOGOUT,
	UPDATE_STATE,
	EMPTY_UPDATED_STATE,
} from '../constants/authActions';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REGISTER_REQUEST:
			return { loading: true };
		case USER_REGISTER_SUCCESS:
			return { netWorkFail: false, loading: false, userInfo: action.payload };
		case UPDATE_STATE:
			return { netWorkFail: false, loading: false, userInfo: action.payload };
		case EMPTY_UPDATED_STATE:
			return {};
		case USER_REGISTER_FAIL:
			return {
				netWorkFail: true,
				loading: false,
				error: action.payload,
			};
		case USER_LOGOUT:
			return {};
		default:
			return { ...state };
	}
};

export const updatedValuesReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REGISTERED_VALUES:
			return { userValues: action.payload };
		case USER_LOGOUT:
			return {};

		default:
			return { ...state };
	}
};

export const updateUserData = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_STATE:
			return { userInfo: action.payload };

		default:
			return { ...state };
	}
};
