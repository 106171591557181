import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
	withRouter,
	useLocation,
	useRouteMatch,
} from 'react-router-dom';

import LoginScreen from './screens/LoginScreen';
import RewardScreen from './screens/RewardScreen';
import OrderScreen from './screens/OrderScreen';
import BackOfficeScreen from './screens/BackOfficeScreen';
import BackOfficeLogin from './screens/BackOfficeLogin';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Register from './screens/Register';

const App = ({ history }) => {
	return (
		<div className="App">
			<Router history={history}>
				<Container>
					<Switch>
						<Route path="/" component={LoginScreen} exact />

						<Route path="/claimrewards/:id" component={RewardScreen} />
						<Route path="/orderscreen/:id" component={OrderScreen} />
						<Route path="/backofficelogin" component={BackOfficeLogin} />
						<Route path="/register" component={Register} />
						<AuthenticatedRoute path="/backoffice" component={BackOfficeScreen} />
					</Switch>
				</Container>
			</Router>
		</div>
	);
};

export default App;
