import {
    BACK_OFFICE_PROPERTY_LIST_REQUEST,
    BACK_OFFICE_PROPERTY_LIST_SUCCESS,
    BACK_OFFICE_PROPERTY_LIST_FAIL,
} from '../constants/backOfficeActions.js';

const initialState = {
    loading: false,
    networkFail: false,
    propertyList: null,
    error: null,
};

export const propertyNamesReducer = (state = initialState, action) => {
	switch (action.type) {
		case BACK_OFFICE_PROPERTY_LIST_REQUEST:
			return { ...state, loading: true };
		case BACK_OFFICE_PROPERTY_LIST_SUCCESS:
			return { ...state, networkFail: false, loading: false, propertyList: action.payload };
		case BACK_OFFICE_PROPERTY_LIST_FAIL:
			return { ...state, networkFail: true, loading: false, error: action.payload };
		default:
			return state;
	}
};
