import axios from 'axios';

import {
	BACKOFFICE_LOGIN_REQUEST,
	BACKOFFICE_LOGIN_SUCCESS,
	BACKOFFICE_LOGIN_FAIL,
	BACKOFFICE_LOGOUT,
	BACKOFFICE_REGISTER_SUCCESS,
	BACKOFFICE_REGISTER_FAIL,
} from '../constants/backOfficeAuthActions';
import BackOfficeAuthService from '../services/BackOfficeAuthService';

export const register = (username, password) => async (dispatch) => {

	try {
		console.log("Calling register");
		const response = await BackOfficeAuthService.register(username, password);
		console.log("Register returned with " + response.data.message);
		dispatch({
			type: BACKOFFICE_REGISTER_SUCCESS,
			payload: response.data.message
		});
	}
	catch (error) {
		const message = (
			error.response &&
	  		error.response.data &&
	  		error.response.data.message) ||
			error.message ||
			error.toString();

  		dispatch({
			type: BACKOFFICE_REGISTER_FAIL,
	  		payload: message,
  		});
	}

};

export const backOfficeLoginRequest = (username, password, id) => async (dispatch, getState) => {

	try {

		dispatch({
			type: BACKOFFICE_LOGIN_REQUEST,
		});

		// Tell the auth service to execute the login
		const token = await BackOfficeAuthService.login(username, password);

		// Register the token with Redux store
		dispatch({
			type: BACKOFFICE_LOGIN_SUCCESS,
			payload: token,
		});

	} catch (error) {
		const message = (error.response &&
			error.response.data &&
			error.response.data.message) ||
			error.message ||
			error.toString();
		console.log("Login failed with error " + message);
		dispatch({
			type: BACKOFFICE_LOGIN_FAIL,
			payload: "Login failed",
		});

	}

};

export const logout = () => (dispatch, getState) => {

	console.log("logout action called");
	BackOfficeAuthService.logout();

	dispatch({
		type: BACKOFFICE_LOGOUT,
	});

};
